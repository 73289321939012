<template>
    <div></div>
</template>

<script>
import {mapMutations} from "vuex";
import { removeCookie } from "@/helpers/functions";
import { LINK_ACCOUNT_KEY, PARENT_ACCOUNT_KEY } from "@/configs/constants";

export default {
    name: "Logout",
    methods: {
        ...mapMutations([
            'setUserData'
        ]),
    },
    mounted() {
        // clear localStorage
        localStorage.removeItem('noodzly_lang')
        localStorage.removeItem('email_verified')
        localStorage.removeItem('home_filters')
        
        removeCookie(LINK_ACCOUNT_KEY);
        removeCookie(PARENT_ACCOUNT_KEY);

        this.$auth
            .logout({
                makeRequest: false,
                redirect: {name: 'login'},
            })
            .then(() => {
                //this.setUserData({})
                // this.$beamsClient.clearAllState()
                //     .then(() => console.log('Beams state has been cleared'))
                //     .catch(e => console.error('Could not clear Beams state', e));
            });
        //this.$router.push('/')
    }
}
</script>

<style scoped>

</style>
